import { createRouter, createWebHistory } from 'vue-router'
import store from '@/config/store';
//process.env.BASE_URL
const routerHistory = createWebHistory()
const req = require.context('../modules', true, /routes$/);
const allroutes = [
    {
        path: '/valid',
        component: () => import('@/components/Valid'),
        title: 'Constancia válida',
    },
    {
        path: '/validate/:id',
        component: () => import('@/components/Validate'),
        title: 'Constancia válida',
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404'),
        title: '404 página no encontrada',
    },
    {
        path: '/wpanel',
        component: () => import('@/modules/wpanel/Home'),
    },
    {
        path: '/wpanel/login',
        component: () => import('@/modules/wpanel/Login'),
    },

];

req.keys().forEach((key) => {
  req(key).default.forEach((key2) => {
    allroutes.push(key2);
  });
});

const router = new createRouter({
  history: routerHistory,
  routes: allroutes,
});

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(nearestWithMeta) {
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      })
      .forEach(tag => document.head.appendChild(tag));
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn === true) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


export default router;
